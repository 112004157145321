@import "../common/mixins.scss";

.games-container {
    @include wrapper();

    .wrapper {
        .page-header {
            display: flex;
            align-items: center;
            padding: 40px 0;
            @media screen and (min-width: 800px) {
                top: 100px;
            }
            background-color: #fff;
            .page-title {
                @include h2();
            }
        }

        .rulebook-body {
            position: relative;
            img {
                width: 100%;
            }

            .rulebook-body-text {
                @include h1();
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 50px;
                height: 100%;
                color: white;
                a {
                    color: white;
                }

                .sub-text {
                    font-size: 18px;
                }
            }
        }

        .featured-list {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;

            .card {
                position: relative;
                width: 250px;
                height: 300px;
                margin: 10px 0;
                padding: 30px;
                border-radius: 25px;
                box-shadow: 0 0 10px #f3f3f3;
                overflow: hidden;
                cursor: pointer;
                transition: 0.3s ease-out all;

                @media screen and (max-width: 800px) {
                    height: auto;
                    width: 100%;
                }

                &:hover {
                    border-color: $primary;
                    .download {
                        background-color: #0c7489;
                    }

                    .icon {
                        background-color: #0c7489;
                        border-color: #0c7489;
                        color: #fff;
                    }
                }

                .icon {
                    padding: 8px 7px;
                    border: 1px solid #c0c0c0;
                    border-radius: 20px;
                    width: 40px;
                }

                .title {
                    padding: 20px 0;
                    font-size: 20px;
                }

                .download {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding: 18px;
                    background-color: $primary;
                    color: #fff;
                    transition: 0.3s ease-out all;
                }
            }
        }

        .other-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .item {
                position: relative;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #e1e1e1;
                margin-bottom: 20px;
                padding: 20px;
                width: 500px;
                cursor: pointer;
                transition: 0.3s ease-out all;

                &:hover {
                    background-color: #e1e1e1;
                }
                @media screen and (max-width: 800px) {
                    width: 100%;
                }

                .title {
                    padding: 0 20px;
                    font-size: 1.7em;
                }

                .download {
                    position: absolute;
                    right: 20px;
                    top: 13px;
                    border-radius: 20px;
                    padding: 8px;
                    background-color: $primary;
                    color: #fff;
                }
            }
        }
    }
}
