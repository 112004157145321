@import "../common/mixins.scss";

.events-container {
    @include wrapper();

    &.fade-enter-active .make-animated {
        @include animated();
    }

    &.fade-exit-active {
        .make-animated {
            @include animated();
        }

        .events-lists .bg-layer {
            -webkit-animation-name: fadeOutDown;
            animation-name: fadeOutDown;
        }
    }

    .wrapper {
        .page-header {
            display: flex;
            align-items: center;
            padding: 40px 0;
            position: sticky;
            @media screen and (min-width: 800px) {
                top: 100px;
            }
            background-color: #fff;
            .page-title {
                @include h2();
            }
            .splitter {
                display: flex;
                align-items: center;
                width: 70px;
                // margin-top: -30px;
                transform: rotate(120deg);
                div {
                    height: 1px;
                    width: 100%;
                    background-color: #000;
                }
            }

            .page-sub {
                font-size: 22px;
                letter-spacing: 4px;
            }
        }
        .events-list {
            display: grid;
            grid-row-gap: 40px;

            @media screen and (min-width: 800px) {
                grid: auto/repeat(auto-fill, 380px);
                grid-column-gap: 30px;
                justify-items: center;
                .item-wrapper {
                    width: 380px;
                }
            }

            .item-wrapper {
                .bg-layer {
                    background: #c0c0c0;
                    height: 220px;
                }

                .ev-date {
                    padding: 15px 0 0;
                    color: #fa311f;
                    font-size: 23px;
                    letter-spacing: 2px;
                }

                .ev-name {
                    font-size: 28px;
                    color: #4c4d4f;
                }
            }
        }
    }

    .events-gallery {
        display: grid;
        grid-row-gap: 40px;

        @media screen and (min-width: 800px) {
            grid: auto/repeat(auto-fill, 400px);
            grid-column-gap: 30px;
            justify-items: center;
        }
        .events-gallery-thumb {
            width: 100%;
            height: 300px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
    }
}
