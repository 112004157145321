@import "../common/mixins.scss";

.videos-container {
    background: url("../assets/bg-events-timeline.jpg") center;
    .videos-header .wrapper {
        @include wrapper();
        padding: 0 40px !important;
        width: 100%;
        height: 80px;
        display: grid;
        grid-template-columns: 1fr auto;
        overflow: hidden;
        .page-name {
            padding-right: 70px;
            height: 100%;
            overflow: hidden;
            div {
                color: #fff;
                font-size: 80px;
                opacity: 0.7;
            }
        }
    }

    .videos-body {
        background-color: #040404;
        min-height: 600px;
        box-shadow: 0 0 50px #000;
        padding: 50px 0;
        .wrapper {
            @include wrapper();
            @media screen and (min-width: 1000px) {
                display: grid;
                grid-template-columns: 70% 1fr;
                width: 100%;
                // flex-wrap: wrap;
                .player {
                    display: grid !important;
                }
            }
            .player {
                display: none;
                height: 100%;
                max-width: 800px;
                border-bottom: 1px solid #5f5a5a;
                border-top: 1px solid #5f5a5a;
                .vembed {
                    width: 100%;
                    height: 500px;
                }
            }

            .video-list {
                border: 1px solid #5f5a5a;
                padding: 0 10px;
                .item {
                    padding: 30px 40px;
                    border-bottom: 1px solid #5f5a5a;
                    cursor: pointer;
                    &:last-child {
                        // border: 0;
                    }
                    &.active {
                        background-color: #fff;
                        .details,
                        .title {
                            color: #040404 !important;
                        }
                    }
                    .title {
                        font-size: 20px;
                        letter-spacing: 2px;
                        color: #fff;
                    }

                    .details {
                        padding-top: 10px;
                        color: #fff;
                    }
                }
            }
        }
    }
}
