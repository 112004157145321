@mixin h1 {
    font-family: "Bebas Neue", cursive;
    font-size: 50px;
}

@mixin h2() {
    font-family: "Bangers", cursive;

    font-size: 2.3em;
}

$primary: #68b0ab;

@mixin animated() {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@mixin card() {
    box-shadow: 0 0 10px #c0c0c0;
    border-radius: 5px;
}
@mixin container {
}
@mixin wrapper() {
    padding: 20px;
    margin: auto;

    @media screen and (min-width: 1280px) {
        width: 1280px;
        padding: 40px;
    }
}

@mixin button-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 solid #fff;
    border-radius: 5px;
    background: none;
    outline: 0;
    height: 50px;
    min-width: 200px;
    padding: 0 30px;
    background-color: #a31621;
    color: #fff;
    font-family: "Bebas Neue", cursive;
    font-size: 18px;
}

@mixin button-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    border-radius: 5px;
    background: none;
    outline: 0;
    height: 50px;
    min-width: 200px;
    padding: 0 30px;
    color: #fff;
    font-family: "Bebas Neue", cursive;
    font-size: 18px;

    @media screen and (max-width: 800px) {
        height: 40px;
        font-size: 15px;
    }
}
