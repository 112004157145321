body {
  font-family: "Bebas Neue", cursive;
  font-size: 13px;
  color: #333;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  color: #000;
  text-decoration: none;
  outline: 0;
  &:hover {
    color: #4a9103;
    text-decoration: none;
  }
}
h1 {
  margin: 0;
  padding: 0;
  padding-bottom: 0px;
  padding-bottom: 1em;
  font-size: 2.2em;
  font-weight: 400;
  color: #000;
  font-family: Pacifico, cursive;
}
h2 {
  font-size: 1.6em;
}
h3 {
  font-size: 1.3em;
  margin: 0 0 1.5em;
  padding: 0.5em 0;
  border-bottom: 1px dashed #000;
}

h2,
h3 {
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
}
h1,
h2,
h3 {
  span {
    color: #4a9103;
  }
  span.red {
    color: #f06040;
  }
}
label {
  padding: 0;
  margin: 0;
}

.cursor {
  cursor: pointer;
}
.site-wrapper {
  min-height: 100vh;
  // background: #f1f4ff;
  // #f3f5f9

  &.hide {
    display: none;
  }
  // display: none;
  // animation: triggerContent 1s 5s ease both;
}

.site-sections {
  // display: ;
  height: 100%;
}

.main-body-contents {
  box-shadow: -35px 0 200px #f3f3f3;
  z-index: 9;
}

@keyframes triggerContent {
  from {
    display: none;
  }
  to {
    display: grid;
  }
}
