@import "../common/mixins.scss";

.facilities-container {
    @include wrapper();

    .wrapper {
        .page-header {
            display: flex;
            align-items: center;
            padding: 40px 0;
            @media screen and (min-width: 800px) {
                top: 100px;
            }
            background-color: #fff;
            .page-title {
                @include h2();
            }
        }

        .page-body {
            display: flex;
            flex-wrap: wrap;
            font-size: 18px;
            font-family: Arial, Helvetica, sans-serif;

            .page-body-content {
                width: 60%;
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
            }
            .thumb {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                width: 40%;
                padding: 0 50px;
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
                img {
                    width: 100%;
                    margin-top: 20px;
                }
            }

            .split-img {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                img {
                    width: 40%;
                    @media screen and (max-width: 800px) {
                        width: 100%;
                    }
                }
            }
            .sub-text {
                @include h1();
                font-size: x-large;
                color: $primary;
                margin-top: 40px;
            }
        }
    }
}
