@import "../common/mixins.scss";

.about-container {
    .about-body {
        @include wrapper();
        .page-title {
            @include h2();
            padding: 40px 0;
            margin-bottom: 40px;
            border-bottom: 1px solid #e1e1e1;
        }
        .contents {
            max-width: 800px;
        }
        .title {
            font-size: 25px;
            letter-spacing: 2px;
            color: #13505b;
        }

        .para {
            padding: 30px 0;
            font-size: 19px;
            color: #3d3b30;
            letter-spacing: 1px;
        }

        .quote {
            // display: grid;
            justify-items: center;
            grid-template-columns: 50px auto;
            grid-template-rows: auto auto;
            justify-content: center;
            grid-gap: 20px;
            padding: 20px 0;
            display: none;
            .img {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                grid-area: 1/1/-1/1;
                .ico {
                    width: 30px;
                    height: 30px;
                }
            }

            .text {
                grid-area: 1/2;
                max-width: 400px;
                color: #a31621;
                font-size: 25px;
                letter-spacing: 2px;
            }

            .author {
                grid-area: 2/2;
                font-size: 18px;
            }
        }
    }
}

.history-body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .history-body-content {
        position: relative;
        font-size: 16px;
        overflow: hidden;
        font-family: Arial, Helvetica, sans-serif;

        .read-more {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: none;
            text-align: right;
            padding: 10px 15px;
            background-color: ghostwhite;
            font-weight: bold;
            letter-spacing: 2px;
            cursor: pointer;
        }

        &:not(.show) {
            max-height: 400px;
            .read-more {
                display: block;
            }
        }

        @media screen and (min-width: 850px) {
            width: 60%;
        }
    }

    .img-wrapper {
        width: 70%;
        padding: 30px 0;
        @media screen and (min-width: 850px) {
            width: 40%;
            padding-left: 40px;
        }
        img {
            width: 100%;
            border: 1px dashed gray;
            padding: 15px;
        }
    }
}
