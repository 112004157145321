@import "../common/mixins.scss";

.sidebar_main {
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 40px;
    align-items: center;
    width: 100%;
    background: #000;
    z-index: 9999;

    @media screen and (max-width: 800px) {
        grid-template-columns: auto auto;
        border-bottom: 1px solid #c0c0c0;
        background-color: #fff;
    }

    .brand_name {
        display: flex;
        align-items: center;
        padding: 20px 50px 20px 30px;
        background-color: #fff;
        color: #000;
        @media screen and (max-width: 800px) {
            padding: 5px 20px;
            img {
                width: 60px !important;
            }
        }
        img {
            width: 80px;
            margin-right: 20px;
        }
        .label {
            @include h2();
            font-size: 25px;
            text-transform: uppercase;
            line-height: 22px;
            @media screen and (max-width: 800px) {
                font-size: 18px;
                line-height: 18px;
            }
            &:hover {
                color: #2a2a2a;
            }
        }
        .sub {
            font-size: 0.7em;
        }
    }
    .mob-trigger {
        display: none;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        @media screen and (max-width: 1200px) {
            display: flex;
        }

        .wrapper {
            display: flex;
            align-items: center;
            background-color: #000;
            height: 100%;
            color: #fff;
            padding: 0 20px;
            cursor: pointer;
        }
    }
    .mobile-menu-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.9);
        .mobile-menu-item {
            display: flex;
            justify-content: center;
            padding: 20px;
            font-size: 1.4em;
        }

        .close-menu {
            position: absolute;
            right: 0;
            top: 0;
            background-color: #000;
            color: #fff;
            padding: 20px;
        }
    }
    .nav-wrap {
        position: relative;
        @media screen and (max-width: 1200px) {
            display: none;
        }
        .main_menu {
            display: flex;
            // font-family: "Lobster", cursive;
            font-family: "Bangers", cursive;
            letter-spacing: 5px;
            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: left;
                color: #fff;
                font-size: 1.7em;
                opacity: 0.5;
                transition: 0.3s ease-in all;
                label {
                    padding: 10px 50px;
                }

                .icon {
                    display: none;
                }
                &:hover {
                    color: #fff;
                    label {
                        cursor: pointer;
                    }
                }

                &.active {
                    color: #fff;
                    opacity: 1;
                    animation: setActiveMenu 0.3s 0.5s ease-in both;
                    label {
                    }
                    .icon {
                        display: block;
                    }
                }
            }
        }
        .brand_logo {
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 2px solid #fff;
            img {
                max-width: 80%;
                max-height: 80%;
            }
        }
    }
}

.home-main-jumbotron {
    position: relative;
    height: 60vh;
    max-width: 100vw;
    background: url("../assets/main-banner4.jpg") center bottom #042446;
    background-size: 100%;
    overflow: hidden;
    @media screen and (max-width: 800px) {
        height: 60vh !important;
        background-size: 400vw;
    }
    .wrapper {
        @include wrapper();
        overflow: hidden;
        @media screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .main-text {
        @include h2();
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: 50px;
        z-index: 1;
        letter-spacing: 5px;
        color: #fff;

        @media screen and (max-width: 800px) {
            font-size: 30px;
            justify-content: center;
        }

        .cta {
            margin-top: 40px;
            button {
                @include button-primary();
            }
        }
    }
    .bg-1 {
        position: absolute;
        left: -10%;
        top: -100%;
        width: 150%;
        height: 100%;
        box-shadow: 0px 60px 1000px #a31621;
    }

    .bg-2 {
        position: absolute;
    }
}

.membership-container {
    background-color: #468189;
    .wrapper {
        @include wrapper();
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        @media screen and (max-width: 800px) {
            flex-direction: column;
            button {
                margin: 10px 0 0 !important;
            }
        }
        .label {
            font-size: 25px;
            font-weight: bold;
            letter-spacing: 0.1em;
        }

        button {
            @include button-secondary();
            margin-left: 50px;
        }
    }

    .newsticker {
        letter-spacing: 1px;
        color: white;
        font-size: 1.2em;
    }
}

.home_container {
    @include wrapper();
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 20px;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .bg-layer {
        overflow: hidden;
    }

    &.fade-enter-active .make-animated {
        @include animated();
    }

    &.fade-exit-active {
        .make-animated {
            @include animated();
        }
        overflow: hidden;
        .home-contents .bg-layer {
            -webkit-animation-name: slideOutLeft;
            animation-name: slideOutLeft;
        }

        .home-video-preview .bg-layer {
            -webkit-animation-name: slideOutLeft;
            animation-name: slideOutUp;
        }

        .home-events-preview .bg-layer {
            -webkit-animation-name: slideOutDown;
            animation-name: slideOutDown;
        }
    }

    .home-contents {
        @media screen and (min-width: 800px) {
            grid-area: 1 / 1 / -1 / 1;
        }

        .bg-layer {
            @media screen and (min-width: 800px) {
                display: grid;
                grid-template-rows: 1fr 2fr;
                height: 100%;
            }

            .home-jumbotron {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 30px;

                .title {
                    font-size: 25px;
                    width: 60%;
                }

                @media screen and (max-width: 800px) {
                    .title {
                        width: 100%;
                    }
                    text-align: center;
                    padding: 30px 0;
                }

                .home-rulebook {
                    @include h1();
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 50px;
                    height: 150px;
                    padding: 0 5%;
                    background-color: #797979;
                    background: url(../assets/extras/rulebook-banner.jpg) no-repeat;
                    background-size: 130%;
                    color: white;
                    border-radius: 5px;
                    box-shadow: 0 0 5px #555555;

                    @media screen and (max-width: 800px) {
                        font-size: 1.2em;
                        background-size: 300%;
                    }
                    a {
                        color: white;
                    }

                    .more {
                        margin-top: 0px;
                        font-size: 18px;
                    }
                }
            }
            .home-banner {
                @include card();
                position: relative;
                overflow: hidden;
                .header {
                    @include h2();
                    position: absolute;
                    top: 10%;
                    left: 10%;
                    color: #fff;
                    z-index: 1;
                }
                img {
                    max-width: 100%;
                    min-height: 100%;
                }
                .slider-img {
                    max-height: 600px;
                    overflow: hidden;

                    @media screen and (max-width: 800px) {
                        height: 400px;
                    }
                }

                .home-facilities {
                    @include h1();
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 50px;
                    height: 150px;
                    padding: 0 5%;
                    background-color: #4a7c59;
                    background: url(../assets/main-banner.jpg) no-repeat;
                    background-size: 150%;
                    background-position: 0 -150px;
                    color: white;
                    border-radius: 5px;
                    box-shadow: 0 0 5px #4a7c59;

                    @media screen and (max-width: 800px) {
                        font-size: 1.2em;
                    }

                    .more {
                        margin-top: 0px;
                        font-size: 18px;
                        a {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .home-video-preview {
        @media screen and (min-width: 800px) {
            grid-area: 1 / 2;
        }

        .bg-layer {
            @include card();
            position: relative;
            height: 100%;
            max-height: 500px;
            background: #8fc0a9;
            overflow: hidden;
            cursor: pointer;

            &:hover {
                .video-label {
                    font-size: 2.5em;
                    @media screen and (max-width: 800px) {
                        font-size: 25px;
                    }
                    svg {
                        height: 70px;
                        width: 70px;
                    }
                }
            }

            img {
                max-width: 100%;
            }

            .video-label {
                @include h2();
                position: absolute;
                bottom: 0;
                padding: 40px;
                color: #fff;
                z-index: 1;
                transition: 0.3s ease all;
                @media screen and (max-width: 800px) {
                    font-size: 25px;
                }
                svg {
                    height: 50px;
                    width: 50px;
                    transition: 0.3s ease all;
                }
                .label {
                    margin-top: 20px;
                }
            }
            .overlay {
                position: absolute;
                bottom: -100%;
                width: 150%;
                height: 100%;
                box-shadow: -70px -200px 450px #4a7c59;
            }
        }
    }
    .home-events-preview {
        @media screen and (min-width: 800px) {
            grid-area: 2 / 2;
        }

        .bg-layer {
            @include card();
            background: #c8d5b9;
            height: 100%;
            width: 100%;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 50px;
                background: #000;
                .h-label {
                    padding: 0 20px;
                    color: #fff;
                    font-size: 18px;
                }
                .controls {
                    display: flex;
                    display: none;
                    .btn-nav {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fff;
                        width: 50px;
                        height: 50px;
                        margin-left: 2px;
                        counter-reset: #000;
                    }
                }
            }

            .event-data {
                padding: 20px 20px;
                .e-date {
                    display: flex;
                    align-items: center;
                    font-size: 1.2em;
                    margin-bottom: 0px;
                    padding: 10px 20px;
                    &:not(:last-child) {
                        border-bottom: 1px solid ghostwhite;
                    }
                    .icon {
                        margin-right: 20px;
                    }
                }

                .position {
                    min-width: 20px;
                    margin-right: 10px;
                }

                .e-name {
                    @include h2();
                    font-size: 1.3em;
                    letter-spacing: 2px;
                }

                .cn-icon {
                    width: 30px;
                    height: 20px;
                    background: url(../assets/extras/World-flags.gif) no-repeat;
                    background-size: 600px;
                    margin-right: 15px;

                    &.au {
                        background-position: -433px 0;
                    }

                    &.nz {
                        background-position: -347px -368px;
                    }

                    &.en {
                        background-position: 0 -164px;
                    }

                    &.ja {
                        background-position: -303px -246px;
                    }

                    &.sa {
                        background-position: -217px -491px;
                    }

                    &.ma {
                        background-position: -44px -327px;
                    }

                    &.ug {
                        background-position: -520px -533px;
                    }

                    &.sl {
                        background-position: -390px -450px;
                    }

                    &.wa {
                        background-position: -390px -574px;
                    }

                    &.tt {
                        background-position: -303px -532px;
                    }
                }
            }
        }
    }
}

.home-recruit-banner {
    background: #040404;
    .wrapper {
        @include wrapper();
        display: flex;
        align-items: center;
        height: 450px;
        padding: 0 100px;
        @media screen and (max-width: 800px) {
            height: 300px;
            padding: 0;
            justify-content: center;
        }
        .text {
            display: grid;
            grid-template-columns: auto auto auto;
            grid-template-rows: auto auto;
            width: 50%;
            color: #fff;
            @media screen and (max-width: 800px) {
                width: 80%;
                grid-row-gap: 20px;
            }
            .a {
                align-self: center;
                font-size: 50px;
                line-height: 0;
                @media screen and (max-width: 800px) {
                    font-size: 30px;
                }
            }
            .b {
                align-self: center;
                font-size: 130px;
                line-height: 0;
                @media screen and (max-width: 800px) {
                    font-size: 50px;
                }
            }
            .c {
                align-self: end;
                font-size: 50px;
                line-height: 50px;
                @media screen and (max-width: 800px) {
                    font-size: 30px;
                    line-height: 30px;
                }
            }
            .d {
                grid-area: 2/1/2/-1;
                justify-self: center;
                color: #77aca2;
                font-size: 110px;
                letter-spacing: 14px;

                @media screen and (max-width: 800px) {
                    font-size: 40px;
                    letter-spacing: 12px;
                }
            }
        }
    }
}

.recruit-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 100px 0;
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        margin: 0 40px;
        text-align: center;
        @media screen and (max-width: 800px) {
            margin: 40px 0;
        }
        svg {
            height: 60px;
            width: 60px;
            color: #9dbebb;
        }
        .title {
            margin: 20px 0;
            font-size: 22px;
            letter-spacing: 5px;
            color: #468189;
        }
        .para {
            font-size: 16px;
        }
    }
}

.timeline-container {
    background: url("../assets/bg-events-timeline.jpg") center;
    .wrapper {
        @include wrapper();
        position: relative;
        padding: 0 40px !important;
        height: 70px;
        display: grid;
        grid-template-columns: 1fr auto;
        overflow: hidden;

        &.scroller {
            overflow: auto;
        }
        .list-time {
            display: flex;
            width: 100%;
            .item {
                padding: 20px;
                font-size: 20px;
                color: #fff;
                cursor: pointer;
                &:hover,
                &.active {
                    background-color: #fa311f;
                }
            }
        }
        .page-name {
            padding-right: 70px;
            height: 100%;
            overflow: hidden;
            div {
                color: #fff;
                font-size: 80px;
                opacity: 0.7;
            }
        }
    }
}

.footer-container {
    background-color: #f4f4f4;
    .wrapper {
        @include wrapper();
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
            flex-direction: column;
        }

        .brand-name {
            padding: 20px 20px;
            color: #000;
            max-width: 250px;
            @media screen and (max-width: 800px) {
                display: flex;
                align-items: center;
                max-width: none;
                img {
                    width: 60px !important;
                }
            }
            img {
                width: 100px;
                margin-right: 20px;
            }
            .label {
                padding: 20px 0;
            }
        }

        .nav-list {
            min-width: 150px;
            padding: 20px 0;
            @media screen and (max-width: 800px) {
                display: flex;
                flex-wrap: wrap;
            }
            .item {
                padding: 5px 20px;
                font-size: 14px;
                letter-spacing: 1px;
            }
        }

        .footer-contact {
            max-width: 350px;
            @media screen and (max-width: 800px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            .name {
                font-size: 18px;
                padding: 10px 20px;
            }

            .li {
                padding: 10px 0 10px 20px;
                word-break: break-all;
            }
        }
        .footer-about {
            padding: 0 20px;
            max-width: 350px;
            .name {
                font-size: 18px;
                padding: 10px 0;
            }

            .li {
                padding: 10px 0;
            }
        }
    }

    .footer-rights {
        padding: 20px;
        background-color: #77aca2;
        color: #fff;
        text-align: center;
        letter-spacing: 2px;
    }
}

@keyframes setActiveMenu {
    from {
    }
    to {
    }
}
